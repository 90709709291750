exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-routes-about-tsx": () => import("./../../../src/routes/about.tsx" /* webpackChunkName: "component---src-routes-about-tsx" */),
  "component---src-routes-blog-blog-detail-tsx": () => import("./../../../src/routes/blog/BlogDetail.tsx" /* webpackChunkName: "component---src-routes-blog-blog-detail-tsx" */),
  "component---src-routes-classes-tsx": () => import("./../../../src/routes/classes.tsx" /* webpackChunkName: "component---src-routes-classes-tsx" */),
  "component---src-routes-faq-tsx": () => import("./../../../src/routes/Faq.tsx" /* webpackChunkName: "component---src-routes-faq-tsx" */),
  "component---src-routes-legal-tsx": () => import("./../../../src/routes/legal.tsx" /* webpackChunkName: "component---src-routes-legal-tsx" */),
  "component---src-routes-membership-tsx": () => import("./../../../src/routes/Membership.tsx" /* webpackChunkName: "component---src-routes-membership-tsx" */),
  "component---src-routes-movement-challenge-tsx": () => import("./../../../src/routes/MovementChallenge.tsx" /* webpackChunkName: "component---src-routes-movement-challenge-tsx" */),
  "component---src-routes-own-a-studio-tsx": () => import("./../../../src/routes/ownAStudio.tsx" /* webpackChunkName: "component---src-routes-own-a-studio-tsx" */),
  "component---src-routes-studio-studio-detail-tsx": () => import("./../../../src/routes/studio/StudioDetail.tsx" /* webpackChunkName: "component---src-routes-studio-studio-detail-tsx" */),
  "component---src-routes-studio-studio-tsx": () => import("./../../../src/routes/studio/Studio.tsx" /* webpackChunkName: "component---src-routes-studio-studio-tsx" */),
  "component---src-routes-timetable-tsx": () => import("./../../../src/routes/timetable.tsx" /* webpackChunkName: "component---src-routes-timetable-tsx" */),
  "component---src-routes-work-with-us-tsx": () => import("./../../../src/routes/work-with-us.tsx" /* webpackChunkName: "component---src-routes-work-with-us-tsx" */)
}

